.card-section {
  &-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 80px 60px;
    margin-bottom: 40px;
    @include adaptive(1024) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 60px 60px;
    }
    @include adaptive(640) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include adaptive(480) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: $dark;

    &-img {
      margin-bottom: 44px;
      max-height: 350px;
      cursor: pointer;

      &:hover {
        .card-section-item-img_second {
          opacity: 1;
          pointer-events: auto;
        }
      }

      @include adaptive(480) {
        max-height: 280px;
      }

      .img {
        @include adaptive(480) {
          max-height: 280px;
        }
      }

      &_second {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: .3s;
        background: $light;
      }
    }

    &_active {
      width: calc(100% / 6 - 15px);
      margin-right: 18px;

      .card-section-mark {
        color: $dark;
      }

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(6n) {
        margin-right: 0;
        @include adaptive(1240) {
          margin-right: 18px;
        }
      }

      @include adaptive(1240) {
        width: calc(100% / 5 - 23px);
      }

      &:nth-child(5n) {
        @include adaptive(1240) {
          margin-right: 0;
        }
        @include adaptive(1024) {
          margin-right: 18px;
        }
      }

      @include adaptive(1024) {
        width: calc(100% / 3 - 27px);
      }

      &:nth-child(3n) {
        @include adaptive(1024) {
          margin-right: 0;
        }
        @include adaptive(780) {
          margin-right: 18px;
        }
      }

      @include adaptive(780) {
        width: calc(100% / 2 - 36px);
      }

      &:nth-child(2n) {
        @include adaptive(480) {
          margin-right: 0;
        }
      }

      @include adaptive(480) {
        width: 100%;
      }

      &:nth-child(n+6) {
        @include adaptive(1240) {
          margin-top: 20px;
        }

        @include adaptive(1024) {
          margin-top: 0;
        }
      }

      &:nth-child(n+2) {
        @include adaptive(1024) {
          margin-top: 20px;
        }
        @include adaptive(780) {
          margin-top: 0;
        }
      }

      &:nth-child(n+1) {
        @include adaptive(780) {
          margin-top: 20px;
        }
        @include adaptive(480) {
          margin-top: 0;
        }
      }

      &:not(:first-child) {
        @include adaptive(480) {
          margin-top: 20px;
        }
      }
    }
  }

  &-mark {
    position: absolute;
    top: 8px;
    right: 8px;
    color: $gray;

    &:hover {
      color: $dark;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  &-title {
    font-weight: $text-bold;
    font-size: 16px;
    letter-spacing: -.02em;
    margin-bottom: 2px;
    text-align: center;
  }

  &-description {
    text-align: center;
    margin-bottom: 10px;
  }

  &-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: auto;

    &-sale {
      font-size: 14px;
      margin-bottom: 1px;

      span {
        color: $gray;
        padding-right: 11px;
        margin-right: 3px;

        &::before {
          content: '|';
          color: $dark;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &-num {
      font-size: 18px;

      &_sale {
        color: $attention;
      }
    }
  }

  &-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:hover {
      .card-section-size {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &-size {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background: $light;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: .3s;

    &-text {
      display: flex;
      flex-wrap: wrap;

      &-item {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.card {
  &-slider {
    &-arrow {
      display: flex;
      height: 26px;
      width: 26px;
      position: absolute;
      z-index: 3;
      top: 130px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50%;
    }

    &__prev {
      left: -13px;
    }

    &__next {
      right: -13px;
    }
  }
}
