.index-sale {
  &-title {
    margin-bottom: 16px;
  }
  &-main {
    display: grid;
    grid-template-columns: 50% 45%;
    grid-gap: 20px 5%;
    width: 100%;
    max-width: 1150px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    margin-bottom: 29px;
    @include adaptive(640) {
      grid-template-columns: 100%;
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
    justify-self: center;
    @include adaptive(640) {
      max-width: 355px;
    }
    .img {
      @include adaptive(640) {
        max-height: 400px;
        object-fit: contain;
      }
    }
    &_big {
      max-height: 645px;
      @include adaptive(640) {
        max-height: 400px;
      }
    }
    &_small {
      max-height: 545px;
      @include adaptive(640) {
        max-height: 400px;
      }
    }
  }
  &-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: 26px;
      font-weight: $text-medium;
      margin-bottom: 18px;
    }
  }
}
