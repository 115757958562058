.video {
  display: flex;
  flex-direction: column;
  &-img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    &::after {
      content: '';
      background: $dark;
      opacity: .5;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
  &-wrapper {
    padding: 100px 0 126px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 850px;
    margin-right: auto;
    margin-left: auto;
    @include adaptive(640) {
      padding: 50px 0;
    }
  }
  &-title {
    font-size: 20px;
    line-height: 100%;
    color: $light;
    text-transform: uppercase;
    margin-bottom: 16px;
    text-align: center;
  }
  &-description {
    color: $light;
    margin-bottom: 31px;
    text-align: center;
  }
  &-toggle {
    display: flex;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: $light;
    text-decoration: none;
    color: $dark;
    align-items: center;
    justify-content: center;
    transition: .3s;
    &:hover {
      color: $light;
      background: $dark;
    }
  }
}
