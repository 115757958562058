.question {
  &-wrapper {
    display: grid;
    grid-template-columns: 43% 57%;
    grid-gap: 40px 0;
    @include adaptive(840) {
      grid-template-columns: 100%;
    }
  }
  &-connection {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 348px;
    margin-right: auto;
    margin-left: auto;
    &-bottom {
      display: flex;
      align-items: center;
      @include adaptive(480) {
        flex-direction: column;
      }
    }
    &-item {
      color: $dark;
      text-decoration: none;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
    }
  }

  &-phone {
    margin-right: 25px;
    @include adaptive(480) {
      margin-right: 0;
      margin-bottom: 20px;
    }
    &-icon {
      margin-right: 10px;
    }
  }

  &-mail {
    &-icon {
      margin-right: 10px;
    }
    .question-connection-text {
      font-weight: $text-medium;
      &::before {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        height: 1px;
        background: $dark;
      }
    }
  }

  &-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 100%;
    font-weight: $text-medium;
    margin-bottom: 47px;
  }
  &-button {
    margin-bottom: 37px;
  }

  &-follow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 680px;
    padding-left: 20px;
    margin-left: auto;
    @include adaptive(840) {
      padding-left: 0;
      margin-right: auto;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      text-align: center;
      margin-bottom: 19px;
    }
    &-description {
      margin-bottom: 24px;
      text-align: center;
    }
    &-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 24px;
      @include adaptive(980) {
        flex-direction: column;
      }
    }
    &-checks {
      display: flex;
      align-items: center;
      max-width: 191px;
      width: 100%;
      margin-right: 20px;
      @include adaptive(980) {
        margin-bottom: 20px;
      }
    }
    &-checkbox {
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
    }

    &-inputs {
      display: flex;
      align-items: center;
      max-width: 400px;
      width: 100%;
    }

    &-label {
      margin-right: 4px;
    }

    &-policy {
      font-size: 10px;
      text-align: center;
      a {
        color: $dark;
      }
    }
  }
}
