.banner {
  &_big {
    .banner-item {
      max-height: 645px;
      @include adaptive(640) {
        height: 350px;
      }
      @include adaptive(480) {
        height: 300px;
      }
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 335px;
    overflow: hidden;
    @include adaptive(480) {
      height: 300px;
    }
  }
}
