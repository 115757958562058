.brands-description {
  &-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    @include adaptive(640) {
      grid-template-columns: 1fr;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;

    &_cover {
      .brands-description-info {
        position: absolute;
        align-items: center;
        right: 0;
        left: 0;
        bottom: 30px;
        color: $light;
        padding: 0 10px;
      }

      .brands-description-img {
        margin-bottom: 0;
        height: 100%;
      }

      .brands-description-title {
        text-align: center;
      }

      .brands-description-link {
        color: $light;

        &::before {
          background: $light;
        }
      }
    }
  }

  &-img {
    margin-bottom: 18px;
    .img {
      height: max-content;
      max-height: 100%;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
  }

  &-title {
    font-size: 18px;
    font-weight: $text-medium;
    margin-bottom: 10px;
  }

  &-link {
    font-size: 16px;
    font-weight: $text-medium;
    color: $dark;
    text-decoration: none;
    &::before {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0;
      height: 1px;
      background: $dark;
    }
  }
  &_small {
    .brands-description-wrapper {
      padding: 0 62px;
    }
    .brands-description-item:nth-child(2) {
      margin: 50px 0 0 40px;
      .brands-description-img {
        height: 545px;
      }
    }
    .brands-description-img {
      height: 645px;
    }
  }
}
